import { css } from '@emotion/react';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import Router from 'next/router';
import { ChangeEvent, DOMAttributes, useEffect, useState } from 'react';

import PrimaryButton from '../../common-components/component-library/buttons/PrimaryButton';
import TextInput, {
  runValidations,
  validations,
} from '../../common-components/component-library/forms/TextInput';
import Typography from '../../common-components/component-library/text/Typography';

import { EVENT_NAMES, sendEvent } from '../../utils/analytics/analytics';
import useOnMountEffect from '../../utils/hooks/useOnMountEffect';
import { colors, spacing } from '../../utils/styleguide';
import getFirebaseApp from '../../utils/firebase';
import { useAuth } from '../../utils/authContext';
import { sendEmailVerification } from '../../utils/api/user';

import LoginButton from './components/SwitchMethod';
import AlternativeLoginProviders from './AlternativeLoginProviders';
import Header from './components/Header';
import { CheckmarkFilled, MisuseOutline } from '@carbon/icons-react';
import { colorsKurppa } from '../../utils/styleguideKurppa';

type Props = {
  email: string;
  setEmail: (email: string) => void;
};

const SignUp = ({ email, setEmail }: Props) => {
  const { isLoadingUser } = useAuth();

  const [password, setPassword] = useState('');

  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [showAllErrors, setShowAllErrors] = useState(false);
  const [createPasswordError, setCreatePasswordError] = useState<FirebaseError>();

  useOnMountEffect(() => {
    sendEvent({
      name: EVENT_NAMES.signUpViewPage,
      properties: undefined,
    });
  });

  useEffect(() => {
    const passwordIsValid = runValidations(validations.password, password);

    if (!passwordIsValid) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  }, [email, password]);

  const continueSignUp: DOMAttributes<HTMLFormElement>['onSubmit'] = async (e) => {
    e.preventDefault();

    const hasEmailValidationError = runValidations(validations.email, email);

    const hasPasswordValidationError = runValidations(validations.password, password);

    if (hasEmailValidationError || hasPasswordValidationError) {
      return setShowAllErrors(true);
    }

    setIsCreatingAccount(true);

    try {
      const firebaseApp = await getFirebaseApp();
      const credentials = await createUserWithEmailAndPassword(
        getAuth(firebaseApp),
        email,
        password,
      );

      const { step, ...query }: typeof Router.query = { ...Router.query, email };
      await Router.replace({
        pathname: Router.pathname,
        query,
      });

      const firebaseToken = await credentials.user?.getIdToken(true);
      await sendEmailVerification(firebaseToken, window.location.href);

      setIsCreatingAccount(false);
    } catch (error) {
      sendEvent({
        name: EVENT_NAMES.createPasswordFailed,
        properties: {
          error,
        },
      });
      if (error instanceof FirebaseError) {
        setCreatePasswordError(error);
      }
      setIsCreatingAccount(false);
    }
  };

  return (
    <>
      <Header title="Sign up with Milkywire" />
      <form onSubmit={continueSignUp}>
        <TextInput
          css={css`
            font-size: 16px;
            margin-bottom: ${spacing[3]}px;
          `}
          autoFocus
          label="Email"
          id="email"
          type="email"
          name="email"
          value={email}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
          validations={validations.email}
          validateWithoutFocus={showAllErrors}
          skipBlurValidation
        />
        <TextInput
          css={css`
            font-size: 16px;
            margin-bottom: ${spacing[1]}px;
          `}
          label="Create a password"
          id="password"
          type="password"
          name="password"
          value={password}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
          validations={validations.password}
          validateWithoutFocus={showAllErrors}
          skipBlurValidation
          error={getCreatePasswordError({ error: createPasswordError, email })}
        />
        <div
          css={css`
            display: flex;
            gap: ${spacing[1]}px;
            padding-bottom: ${spacing[4]}px;
          `}
        >
          {passwordValid ? (
            <CheckmarkFilled color={colorsKurppa.green400} />
          ) : (
            <MisuseOutline color={colorsKurppa.red400} />
          )}{' '}
          <Typography variant="detail">Minimum 6 characters</Typography>
        </div>

        <PrimaryButton
          type="submit"
          label={'Sign up'}
          disabled={!passwordValid}
          loading={isCreatingAccount || isLoadingUser}
          variant="solid"
          size="small"
          css={css`
            display: block;
            margin: 0 auto;
            width: 100%;
          `}
        />
      </form>

      <AlternativeLoginProviders type="signup" />

      <Typography
        variant="bodySmall"
        color={colors.darkGray}
        css={css`
          display: flex;
          justify-content: center;
          gap: ${spacing[1]}px;
        `}
      >
        <span>Already have an account?</span>
        <LoginButton label="Log in" textVariant={'bodySmallBold'} step="login" email={email} />
      </Typography>
    </>
  );
};

const getCreatePasswordError = ({ error, email }: { error?: FirebaseError; email: string }) => {
  if (error?.code === 'auth/email-already-in-use') {
    return (
      <>
        There is already an account with this email. Please{' '}
        <LoginButton step="login" label="Log in" email={email} textVariant="detail" /> instead. If
        this was unexpected contact us at{' '}
        <a href="mailto:support@milkywire.com">support@milkywire.com</a>.
      </>
    );
  } else if (error) {
    return (
      <>
        Something went wrong. Please try again later or contact us at{' '}
        <a href="mailto:support@milkywire.com">support@milkywire.com</a>.
      </>
    );
  }
};

export default SignUp;
