import { CurrentUserResponse } from '../../types/UserTypes';
import { authenticatedFetch, getFromGalaxyCDN, handleMiscErrors } from '../api';
import getConfig from '../config';
import { client, AuthenticationTypes } from '../galaxyClient';

export const deleteAccount = async (userId: number) => {
  await authenticatedFetch(`users/v2/users/${userId}`, {
    method: 'DELETE',
  });
};

export const getCurrentUser = async () =>
  await authenticatedFetch<CurrentUserResponse>('users/v2/me');

export const acceptTermsOfUse = async (slug: string) =>
  await client().users.POST('/v2/me/accept-terms', {
    body: {
      slug,
    },
  });

export const sendEmailVerification = async (firebaseToken?: string, url?: string) => {
  const config = await getConfig();
  const response = await fetch(`${config.API_URL}authentication/v2/firebase/verify-email`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ accessToken: firebaseToken, url }),
  });
  if (response.status !== 200) throw new Error('Failed email verification');
};

export const getLocale = async () =>
  await getFromGalaxyCDN<{
    country: {
      countryCode: string;
      name?: string;
      defaultCurrencyCode: string;
      blockedFor: string[];
    };
  }>('users/v2/locale');

export function claimUserInvite(payload: string, firebaseId: string) {
  return authenticatedFetch(`/authentication/v2/users/invite`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      payload,
      firebaseId,
    }),
  });
}

export async function getJWTToken(
  accessToken: string,
): Promise<
  AuthenticationTypes['/v2/firebase/']['get']['responses']['200']['content']['application/json']
> {
  const config = await getConfig();
  const response = await fetch(
    `${config.API_URL}authentication/v2/firebase?access_token=${accessToken}`,
  );

  if (response.status === 401) {
    const body = await response.json();
    if (body?.code == 103) {
      throw new Error('missing_email');
    }

    if (body?.code == 106) {
      const error = new Error(body.errorMessage);
      error.name = body.name;
      throw error;
    }

    throw new Error();
  }

  await handleMiscErrors(response);

  return await response.json();
}
