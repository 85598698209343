import styled from '@emotion/styled';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { ChangeEvent, DOMAttributes, useState } from 'react';
import GhostButton from '../../common-components/component-library/buttons/GhostButton';
import PrimaryButton from '../../common-components/component-library/buttons/PrimaryButton';
import TextInput, {
  runValidations,
  validations,
} from '../../common-components/component-library/forms/TextInput';
import Typography from '../../common-components/component-library/text/Typography';
import { createTemplateReplacer } from '../../common-components/contentful-elements/util/mergeTagsTemplates';
import { EVENT_NAMES, sendEvent } from '../../utils/analytics/analytics';
import { useMagicLinkCopy } from '../../utils/hooks/useMagicLinkCopy';
import useOnMountEffect from '../../utils/hooks/useOnMountEffect';
import { colors, spacing } from '../../utils/styleguide';
import AlternativeLoginProviders, { EmailAuthenticationMethod } from './AlternativeLoginProviders';
import useResetPasswordUrl from './useResetPasswordUrl';
import getFirebaseApp from '../../utils/firebase';
import { getSignUpWithEmailLink } from '../../utils/api/authentication';
import { useAuth } from '../../utils/authContext';
import { css } from '@emotion/react';
import CreateAccountButton from './components/SwitchMethod';
import Header from './components/Header';

const TextBox = styled(TextInput)`
  font-size: 16px;
  margin-bottom: ${spacing[3]}px;
`;

const ResetPasswordLink = styled(GhostButton)`
  text-align: end;
  margin-bottom: ${spacing[2]}px;
`;

const Submit = styled(PrimaryButton)`
  display: block;
  margin: 0 auto;
  width: 100%;
`;

type Props = {
  email: string;
  setEmail: (email: string) => void;
};

const Login = ({ email, setEmail }: Props) => {
  const { isLoadingUser } = useAuth();
  const magicLinkCopy = useMagicLinkCopy();

  const resetPasswordUrl = useResetPasswordUrl(email);

  const [password, setPassword] = useState('');

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [sentEmailLink, setSentEmailLink] = useState(false);
  const [loginError, setLoginError] = useState<unknown>();
  const [showAllErrors, setShowAllErrors] = useState(false);
  const [emailAuthenticationMethod, setEmailAuthenticationMethod] =
    useState<EmailAuthenticationMethod>('password');

  useOnMountEffect(() => {
    sendEvent({
      name: EVENT_NAMES.viewEnterPassword,
      properties: undefined,
    });
  });

  const logInWithPassword = async ({ email, password }: { email: string; password: string }) => {
    setIsLoggingIn(true);
    setEmail(email);
    setLoginError(undefined);

    try {
      const firebaseApp = await getFirebaseApp();
      await signInWithEmailAndPassword(getAuth(firebaseApp), email, password);

      setIsLoggingIn(false);
    } catch (error) {
      sendEvent({
        name: EVENT_NAMES.loginLoginFailed,
        properties: {
          method: 'password',
          error,
        },
      });

      setIsLoggingIn(false);
      setLoginError(error);
    }
  };

  const createSignInWithEmailLink = async ({ email }: { email: string }) => {
    setIsLoggingIn(true);
    setEmail(email);
    setLoginError(undefined);
    try {
      await getSignUpWithEmailLink({ email });
      localStorage.setItem('@Auth:storedEmail', email);

      setIsLoggingIn(false);
      setSentEmailLink(true);
    } catch (error) {
      sendEvent({
        name: EVENT_NAMES.loginLoginFailed,
        properties: {
          method: 'email-link',
          error,
        },
      });

      setIsLoggingIn(false);
      setLoginError(error);
    }
  };

  const onLogin: DOMAttributes<HTMLFormElement>['onSubmit'] = async (event) => {
    event.preventDefault();
    const hasEmailValidationError = runValidations(validations.email, email);

    if (hasEmailValidationError) {
      return setShowAllErrors(true);
    }

    if (emailAuthenticationMethod === 'password') {
      const hasPasswordValidationError = runValidations(validations.password, password);
      if (hasPasswordValidationError) {
        return setShowAllErrors(true);
      }
      await logInWithPassword({ email, password });
    } else {
      await createSignInWithEmailLink({ email });
    }
  };

  if (emailAuthenticationMethod === 'login-link' && sentEmailLink) {
    const replacer = createTemplateReplacer({ email });
    const text = magicLinkCopy?.magicLink?.noLinkBody
      ? replacer(magicLinkCopy?.magicLink?.noLinkBody)
      : '';
    return (
      <div
        css={css`
          text-align: center;
          display: grid;
          row-gap: ${spacing[3]}px;
        `}
      >
        <Typography variant="h4">
          If you have a Milkywire account, you will get a link sent to your email.
        </Typography>
        <Typography variant="detail">{text}</Typography>
        <Typography
          variant="bodySmall"
          color={colors.darkGray}
          css={css`
            display: flex;
            justify-content: center;
            gap: ${spacing[1]}px;
          `}
        >
          <span>Don&apos;t have an account?</span>
          <CreateAccountButton
            step="sign-up"
            label="Sign up with Milkywire"
            textVariant="bodySmallBold"
            email={email}
          />
        </Typography>
      </div>
    );
  }

  return (
    <>
      <Header title="Log in to Milkywire" />
      <form onSubmit={onLogin}>
        <TextBox
          autoFocus
          label="Email"
          id="email"
          name="email"
          type="email"
          value={email}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
          }}
          validations={validations.email}
          validateWithoutFocus={showAllErrors}
          skipBlurValidation
          error={getEmailLoginError(loginError)}
        />
        {emailAuthenticationMethod === 'password' && (
          <>
            <TextBox
              label="Password"
              id="password"
              name="password"
              type="password"
              onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
              validations={validations.password}
              validateWithoutFocus={showAllErrors}
              skipBlurValidation
            />
            <ResetPasswordLink
              href={resetPasswordUrl}
              label="Forgot your password?"
              hideUnderline
              color={colors.info}
              textVariant="detailBold"
            />
          </>
        )}
        <Submit
          type="submit"
          label={emailAuthenticationMethod === 'password' ? 'Log in' : 'Send me a login link!'}
          loading={isLoggingIn || isLoadingUser}
          variant="solid"
          size="small"
        />
      </form>
      <AlternativeLoginProviders
        type="login"
        emailAuthenticationMethod={
          emailAuthenticationMethod === 'login-link' ? 'password' : 'login-link'
        }
        setEmailAuthenticationMethod={(method: EmailAuthenticationMethod) =>
          setEmailAuthenticationMethod(method)
        }
      />

      <Typography
        variant="bodySmall"
        color={colors.darkGray}
        css={css`
          display: flex;
          justify-content: center;
          gap: ${spacing[1]}px;
        `}
      >
        <span>New to Milkywire?</span>
        <CreateAccountButton
          step="sign-up"
          label="Create an account"
          textVariant="bodySmallBold"
          email={email}
        />
      </Typography>
    </>
  );
};

const getEmailLoginError = (error: unknown) => {
  // NOTE: firebase provide email enumeration protection to not leak information on users auth-details
  // hence the error code is generic and does not inform the user if the user exist or not, this
  // is according to security best practices. Hence the helper message is generic.
  if ((error as { code: string } | undefined)?.code === 'auth/invalid-credential') {
    return (
      <>
        That email/password combination doesn&apos;t seem correct.
        <br />
        Do you want to{' '}
        <CreateAccountButton step="sign-up" label="Create an account" textVariant="detail" />{' '}
        instead?
      </>
    );
  }
};

export default Login;
