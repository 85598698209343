import {
  User,
  getAuth,
  getRedirectResult,
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithEmailLink,
} from 'firebase/auth';
import Router from 'next/router';
import { createContext, useContext, useState, useEffect, useCallback, ReactNode } from 'react';
import { captureException } from './tracking/sentry/sentry';
import { UnauthenticatedProfile, UnverifiedUserProfile, UserProfile } from '../types/AuthTypes';
import { EVENT_NAMES, sendEvent } from './analytics';
import { handleAuthentication, handleSignInRedirectFailed, logout, setUserData } from './auth';
import getFirebaseApp from './firebase';
import { getToken } from './token';
import { isApiError } from './error';

const isUnverifiedUser = (
  user: UserProfile | UnverifiedUserProfile | UnauthenticatedProfile | null,
): user is UnverifiedUserProfile => {
  if (!user) {
    return false;
  }
  return (user as UnverifiedUserProfile).isUnverified;
};

const isUnauthenticatedUser = (
  user: UserProfile | UnverifiedUserProfile | UnauthenticatedProfile | null,
): user is UnauthenticatedProfile => {
  if (!user) {
    return false;
  }
  return (user as UnauthenticatedProfile).isUnauthenticated;
};

const isVerifiedUser = (
  user: UserProfile | UnverifiedUserProfile | UnauthenticatedProfile | null,
): user is UserProfile => {
  if (!user) {
    return false;
  }
  return !isUnverifiedUser(user) && !isUnauthenticatedUser(user);
};

export type AuthContext = {
  user: UserProfile | UnverifiedUserProfile | UnauthenticatedProfile | null;
  verifiedUser: UserProfile | undefined;
  unverifiedUser: UnverifiedUserProfile | undefined;
  isUnauthenticated: boolean;
  isUnverified: boolean;
  isLoadingUser: boolean;
  error: unknown;
  clearUser: () => void;
  fetchUser: () => Promise<void>;
};

const Context = createContext<AuthContext | null>(null);
type Props = {
  children: ReactNode;
};
function AuthProvider(props: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<
    UserProfile | UnverifiedUserProfile | UnauthenticatedProfile | null
  >(null);
  const [error, setError] = useState<unknown>();

  const fetchUser = useCallback(async () => {
    setIsLoading(true);

    const handleError = (error: unknown) => {
      setUser({ isUnauthenticated: true });
      setError(error);
    };

    if (!getToken()) {
      try {
        await handleAuthentication();
      } catch (error) {
        if ((error as { name?: 'UnverifiedEmailError' }).name === 'UnverifiedEmailError') {
          handleError({ authenticationError: 'UnverifiedEmailError' });
        } else {
          captureException(error);
          await logout();
          handleError({ authenticationError: 'AuthenticationError' });
        }
        return;
      }
    }

    const getUser = async () => {
      try {
        return await setUserData();
      } catch (error) {
        if (isApiError(error) && error.status === 401) {
          try {
            await handleAuthentication();
            const user = await setUserData();
            return user;
          } catch (error) {
            if (isApiError(error) && error.status === 401) {
              throw { authenticationError: true };
            }
          }
        }
        throw error;
      }
    };

    try {
      const user = await getUser();

      const returnUrl = localStorage.getItem('@Auth:returnUrl');
      if (returnUrl) {
        Router.replace(returnUrl || '/');
        localStorage.removeItem('@Auth:returnUrl');
      }

      setUser(user);
      setIsLoading(false);
    } catch (error) {
      captureException(error);
      await logout();
      handleError(error);
      setIsLoading(false);
    }
  }, []);

  const clearUser = useCallback(() => {
    setUser({ isUnauthenticated: true });
  }, []);

  const authenticateWithGalaxy = useCallback(
    async ({
      firebaseUser,
      shouldFetchUnverified,
    }: {
      firebaseUser: User | null;
      shouldFetchUnverified?: boolean;
    }) => {
      setIsLoading(true);
      try {
        if (!firebaseUser) {
          clearUser();
          setIsLoading(false);
          return;
        }
        const idTokenResponse = await firebaseUser.getIdTokenResult();
        if (
          firebaseUser.emailVerified ||
          idTokenResponse.signInProvider === 'facebook.com' ||
          shouldFetchUnverified
        ) {
          await fetchUser();
        } else if (!firebaseUser.email) {
          setIsLoading(false);
          setUser({ isUnauthenticated: true });
          setError({ authenticationError: new Error('No firebase email') });
        } else {
          setUser({
            isUnverified: true,
            email: firebaseUser.email,
            name: firebaseUser.displayName || '',
          });
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    },
    [fetchUser, clearUser],
  );

  useEffect(() => {
    const authenticate = async () => {
      const firebaseApp = await getFirebaseApp();
      // TODO: We should be able to get rid of this solution since it was introduced to handle authentication
      // from the app. However, we have tests that depend on this implementation for now that need to be cleaned up.
      if (Router.query?.['custom-token']) {
        try {
          let firebaseUser: User | null = null;
          try {
            const signin = await signInWithCustomToken(
              getAuth(firebaseApp),
              Router.query['custom-token'] as string,
            );
            firebaseUser = signin.user;
          } catch (error) {
            captureException(error);
            firebaseUser = null;
          }

          await authenticateWithGalaxy({
            firebaseUser,
            shouldFetchUnverified: true,
          });
          return;
        } catch (error) {
          captureException(error, {
            tags: {
              authenticationType: 'custom token',
            },
          });
          // continue counting on cache
        }
      }

      if (Router.query?.['mode'] === 'signIn' && Router.query?.['oobCode']) {
        try {
          const storedEmail = localStorage.getItem('@Auth:storedEmail') || '';
          const emailLink = window.location.href;
          const userCredentials = await signInWithEmailLink(
            getAuth(firebaseApp),
            storedEmail,
            emailLink,
          );

          await authenticateWithGalaxy({
            firebaseUser: userCredentials.user,
            shouldFetchUnverified: true,
          });
          localStorage.removeItem('@Auth:storedEmail');
        } catch (error) {
          if (
            !(
              error &&
              typeof error === 'object' &&
              'code' in error &&
              (error.code === 'auth/invalid-action-code' || error.code === 'auth/invalid-email')
            )
          ) {
            captureException(error, {
              tags: {
                authenticationType: 'email-link',
              },
            });
          }
        }
      }

      // setup listener for firebase auth state changes
      onAuthStateChanged(
        getAuth(firebaseApp),
        async (firebaseUser) => {
          sendEvent({
            name: EVENT_NAMES.firebaseAuthStateChanged,
            properties: {
              signedIn: !!firebaseUser,
            },
          });
          await authenticateWithGalaxy({ firebaseUser });
        },
        (error) => {
          sendEvent({
            name: EVENT_NAMES.firebaseAuthStateChanged,
            properties: {
              error,
            },
          });
          setIsLoading(false);
          setUser({ isUnauthenticated: true });
          setError({ authenticationError: error });
        },
      );

      // In case of firebase error in redirect, the error callback of onAuthStateChanged is not called
      // This should throw the error if there was one
      const checkRedirectResult = localStorage.getItem('@Auth:checkRedirectResult');
      if (checkRedirectResult) {
        localStorage.removeItem('@Auth:checkRedirectResult');
        try {
          await getRedirectResult(getAuth(firebaseApp));
        } catch (error) {
          try {
            handleSignInRedirectFailed({ error });
          } catch (e) {
            setError({ authenticationError: e });
          }
        }
      }
    };
    authenticate();
  }, [authenticateWithGalaxy]);

  return (
    <Context.Provider
      value={{
        user,
        verifiedUser: isVerifiedUser(user) ? user : undefined,
        unverifiedUser: isUnverifiedUser(user) ? user : undefined,
        isUnauthenticated: isUnauthenticatedUser(user),
        isUnverified: isUnverifiedUser(user),
        isLoadingUser: isLoading,
        error,
        clearUser,
        fetchUser,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

function useAuth(): AuthContext {
  const context = useContext(Context);
  if (context === null) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }

  return context;
}

export { AuthProvider, useAuth };
